@import '~sanitize.css';
@import '~sanitize.css/forms.css';
@import '~sanitize.css/typography.css';

#root {
    height: 100%;
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('assets/fonts/Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


body,
html {
    font-family: Montserrat, sans-serif;
    background-color: #F3F4F6;
}

body {
    padding-right: 0 !important;
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}

main {
    width: 100%;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}