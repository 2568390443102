.openModalButton {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
  cursor: pointer;
  border-radius: 7px;
}

.openModalButton:hover {
  border-color: blue;
  color: blue;
}

.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.popover {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal {
  position: absolute;
  background-color: white;
  width: 80%;
  height: 80%;
}

.modalContentContainer {
  overflow-y: scroll;
  width: 100%;
  height: 85%;
  padding: 35px;
}

.closeModalButton {
  position: absolute;
  right: 20px;
  top: 5px;
  border: none;
  cursor: pointer;
  color: black;
}

.closeModalButton:hover {
  color: gray;
}

.checkboxLabel {
  cursor: pointer;
}

.featuresContainer {
  margin-bottom: 30px;
}

.featuresContainer:last-child {
  margin-bottom: 0;
}

.footer {
  border-top: 1px solid black;
  display: flex;
  align-items: center;
  background-color: white;
  height: 15%;
  width: 100%;
  padding: 10px;
}

.resetButton {
  cursor: pointer;
}

.resetButton:hover {
  border-color: blue;
  color: blue;
}
